import { Injectable } from "@angular/core";
import * as AppActions from "./coverletter.actions"
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, filter, map, mergeMap, of, switchMap, tap, withLatestFrom } from "rxjs";
import { CoverLetterDataService } from "../services/coverLetter/coverLetter-data.service";
import { PaymentDataService } from "../services/payment/payment-data.service";
import { UserDataService } from "../services/user/user-data.service";
import { AppState, selectCurrentUser } from ".";
import { Store } from "@ngrx/store";
import { DialogNoCreditsComponent } from "../components/dialog-no-credits/dialog-no-credits.component";
import { Dialog, DialogModule, DialogRef } from "@angular/cdk/dialog";
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { LetterDataService } from "../services/letter/letter-data.service";
import { HttpResponse } from "@angular/common/http";
import { ResumeDataService } from "../services/resume/resume-data.service";

@Injectable()
export class AppEffects {

    generateCoverLetter$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AppActions.generateCoverLetter),
            tap((val) => console.log(val)),
            mergeMap(action => 
                this.coverLetterDataService.generateTextFromInputs(
                    action.userId,
                    action.resumeText,
                    action.jobText,
                    action.tone,
                    action.language
                )
                .pipe(
                    map((letter) => AppActions.generateCoverLetterSuccess({letterText: letter.text})),
                    catchError(error => {
                            return of(error);
                        }
                    )
                )
            )
        ),
        {dispatch: true}
    )

    checkoutSession$ = createEffect(() => 
        this.actions$.pipe(
        ofType(AppActions.checkoutSession),
        tap(val => console.log(val)),
        mergeMap(action => 
            this.paymentDataService.checkoutSession(action.productId, action.userId).pipe(
                tap((redirectionUrl: string) => {
                    if (redirectionUrl) {
                      // Perform the redirection using the Router
                      window.open(redirectionUrl);
                    }
                })
            )
        )
        ),
        { dispatch: false }
    );

    updateUserData$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AppActions.generateCoverLetterSuccess, AppActions.removeLetterSuccess, AppActions.modifyLetterSuccess, AppActions.addResumeSuccess, AppActions.removeResumeSuccess),
            tap(val => console.log("Update user data")),
            withLatestFrom(this.store.select(selectCurrentUser)),
            filter(([action, user]) => !!user),
            mergeMap(([action, user]) =>
                this.userService.getUserById(user.id)
                .pipe(
                    map(updatedUser => {
                        return AppActions.updateCurrentUser({ user: updatedUser });
                    }),
                    catchError(error => of(error)),
                )
            )
        ),
    );    
    
    openNoCreditDialog$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AppActions.openNoCreditDialog),
            tap(val => console.log("Opening pop up no credits")),
            map(() => {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.width = '500px';
                dialogConfig.height = '400px';
                dialogConfig.panelClass = 'dialog';
                return this.dialog.open(DialogNoCreditsComponent, dialogConfig)
            }
            )
        ),
        { dispatch: false }
    );

    closeNoCreditDialog$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AppActions.closeNoCreditDialog),
            tap(val => console.log("close pop up no credits")),
            map(() => {
                return this.dialog.closeAll();
            }
            )
        ),
        { dispatch: false }
    );

    removeLetter$ = createEffect(() => 
        this.actions$.pipe(
                    ofType(AppActions.removeLetter),
                    tap(val => console.log("Removing letter")),
                    mergeMap(
                        action => 
                        this.letterDataService.removeLetter(action.letterId)
                        .pipe(
                            map(() => AppActions.removeLetterSuccess())
                        )
                )
            ),
        { dispatch: true }
    );

    modifyLetter$ = createEffect(() => 
        this.actions$.pipe(
                ofType(AppActions.modifyLetter),
                tap(val => console.log("Modifying letter")),
                mergeMap(
                    action => 
                    this.letterDataService.modifyLetter(action.letterId, action.companyName, action.jobTitle, action.language, action.writingStyle, action.text)
                    .pipe(
                        map(() => AppActions.modifyLetterSuccess())
                    )
                )
            ),
        { dispatch: true }
    );

    downloadLetter$ = createEffect(() => 
    this.actions$.pipe(
        ofType(AppActions.downloadLetter),
        tap(val => console.log(val)),
        mergeMap(action => 
        this.letterDataService.downloadLetter(action.letterId).pipe(
            tap((response: HttpResponse<Blob>) => console.log(response.headers.get('filename'))),
            map((data: any) => {
                const blob = new Blob([data.body], { type: 'application/pdf' });
                const filename = data.headers.get('filename')
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.click();
            })
        )
        )
    ),
    { dispatch: false }
    );

    addResume$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AppActions.addResume),
            tap(val => console.log(val)),
            withLatestFrom(this.store.select(selectCurrentUser)),
            mergeMap(
                ([action, user]) => 
                this.resumeDataService.addResume(action.file, user.id)
                .pipe(
                    map(() => AppActions.addResumeSuccess())
                )
            )
        ),
        { dispatch: true }
    );

    removeResume$ = createEffect(() => 
        this.actions$.pipe(
            ofType(AppActions.removeResume),
            tap(val => console.log(val)),
            mergeMap(
                action => 
                this.resumeDataService.removeResume(action.resumeId)
                .pipe(
                    map(() => AppActions.removeResumeSuccess())
                )
            )
        ),
        { dispatch: true }
    );

    constructor(
        private actions$: Actions, 
        private coverLetterDataService: CoverLetterDataService, 
        private paymentDataService: PaymentDataService, 
        private userService: UserDataService,
        private letterDataService: LetterDataService,
        private resumeDataService: ResumeDataService,
        public store: Store<AppState>,
        private dialog: MatDialog,
        public dialogRef: MatDialogRef<DialogNoCreditsComponent>
    ) {}
}