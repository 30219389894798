<div class="app-container">
    <div class="content-dialog flex flex-col text-center justify-center items-center">
        <img width="150" src="../../../assets/logo.png">
        <p class="text-2xl">No credits remaining !</p>
        <p class="text-anchor">To continue generating amazing coverletter please purchase some credits in our store. Starting for as little as 1$</p>
        <div class="flex justify-center">
            <button class="btn-cancel mr-4" mat-button mat-stroked-button color="danger" (click)="close()">Cancel</button>
            <button class="btn-buy-credits" mat-button mat-stroked-button color="primary" (click)="buyCredits()"><mat-icon>storefront</mat-icon>Buy credits</button>
        </div>
    </div>
</div>