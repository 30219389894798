import { Component } from '@angular/core';

@Component({
  selector: 'star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.css']
})
export class StarComponent {
  filled: boolean = false

  click() {
    this.filled = !this.filled;
  }
}
