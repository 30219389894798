<navbar active="pricing"></navbar>
<div class="app-container">
    <div class="flex justify-center items-center pricing-section">
        <div class="flex flex-col">
            <p class="text-4xl text-center title">Buy credits</p>
            <p class="pricing-section-header-two text-xl text-center">Get credits at a good price</p>
            <div class="flex flex-col md:flex-row mt-20">
                <product-card class="mb-10 md:mr-20 bronze" productId="product1" price="1">Pack of 5 letters</product-card>
                <product-card class="mb-10 md:mr-20 silver" productId="product2" price="3">Pack of 10 letters</product-card>
                <product-card productId="product3" price="5">Pack of 20 letters</product-card>
            </div>
        </div>
    </div>
</div>
