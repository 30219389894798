import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Resume } from 'src/app/models/coverLetter';
import { removeResume } from 'src/app/store/coverletter.actions';

@Component({
  selector: 'resume-card',
  templateUrl: './resume-card.component.html',
  styleUrls: ['./resume-card.component.css']
})
export class ResumeCardComponent {

  constructor(private store: Store) {}
  hover: boolean = false
  @Input() resume: Resume | undefined = undefined;

  toggleHover() {
    this.hover = !this.hover;
  }

  removeResume() {
    if (this.resume) {
      this.store.dispatch(removeResume({resumeId: this.resume.id}))
    }
  }
}
