import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest, filter, map, switchMap } from 'rxjs';
import { User } from 'src/app/models/coverLetter';
import { UserDataService } from 'src/app/services/user/user-data.service';
import { AppState, selectCurrentUser } from 'src/app/store';
import { updateCurrentUser } from 'src/app/store/coverletter.actions';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  constructor(public auth: AuthService, public userService: UserDataService, private store: Store<AppState>, @Inject(DOCUMENT) public document: Document) {
  }

  user$: Observable<User> = this.store.select(selectCurrentUser)
  subscription!: Subscription;

  ngOnInit(): void {
    // load user data in store when logged in
    this.subscription = this.auth.isAuthenticated$
      .pipe(
        filter(authenticated => authenticated), // Only proceed if authenticated is true
        switchMap(() => this.auth.user$), // Switch to user$ Observable
        switchMap(userData => this.userService.getUserById(userData?.sub ?? "")) // Switch to getUserById Observable
      )
      .subscribe(userData => {
        this.store.dispatch(updateCurrentUser({user: userData}))
        console.log("Logged in")
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  
}
