import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/coverLetter';
import { AppState, selectCurrentUser } from 'src/app/store';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {

  @Input() active: string = "";
  
  constructor(public auth: AuthService, private store: Store<AppState>, public router: Router, @Inject(DOCUMENT) public document: Document) {}

  user$: Observable<User> = this.store.select(selectCurrentUser)

  @HostListener('window:resize', ['$event'])
  get mobile(): boolean {
    return window.innerWidth < 768
  }
}
