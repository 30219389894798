<div class="flex letter-card p-10" (mouseenter)="showLetterToggle()" (mouseleave)="showLetterToggle()">
    <div *ngIf="!showLetter" class="flex flex-col justify-center text-left">
        <p class="text-xl font-bold">{{companyName}}</p>
        <p class="italic">{{jobTitle}}</p>
        <p>{{language}}</p>
        <p>{{writingStyle}}</p>
        <p>Generated on <span class="font-bold">{{generationDate}}</span></p>
    </div>
    <div *ngIf="showLetter" class="letter-text">
        <p class="text-left">{{letterText}}</p>
        <div class="letter-options flex text-center">
            <button mat-icon-button class="mr-2" [cdkCopyToClipboard]="letterText">
                <mat-icon>content_copy</mat-icon>
            </button>
            <button mat-icon-button [routerLink]="['/edit-letter', letterId]">
                <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="downloadLetter()">
                <mat-icon>download</mat-icon>
            </button>
            <button mat-icon-button class="mr-2" (click)="deleteLetter()">
                <mat-icon class="warn">delete</mat-icon>
            </button>
        </div>
    </div>
</div>