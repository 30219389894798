<div class="app-container mx-auto">
    <navbar></navbar>
    <div class="cover-letter-text">
        <form [formGroup]="letterForm" (submit)="modifyLetter()">
            <div class="flex">
                <div class="flex flex-col letter-data-input text-center items-center">
                    <p class="text-xl text-center">Letter information</p>
                    <mat-form-field class="letter-data">
                        <mat-label>Company name</mat-label>
                        <input formControlName="companyName" matInput id="file-input" type="text"/>
                    </mat-form-field>
                    <mat-form-field class="letter-data">
                        <mat-label>Job title</mat-label>
                        <input formControlName="jobTitle" matInput id="file-input" type="text"/>
                    </mat-form-field>
                    <mat-form-field class="letter-data">
                        <mat-label>Language</mat-label>
                        <input formControlName="language" matInput id="file-input" type="text"/>
                    </mat-form-field>
                    <mat-form-field class="letter-data">
                        <mat-label>Writing style</mat-label>
                        <input formControlName="writingStyle" matInput id="file-input" type="text"/>
                    </mat-form-field>
                    <button type="submit" class="text-xl save-button" mat-button mat-stroked-button color="primary"><mat-icon>save</mat-icon>Save letter</button>
                </div>
                <div class="letter-input">
                    <mat-form-field class="formText">
                        <mat-label>Cover letter</mat-label>
                        <textarea class="letter-text" formControlName="letter" matInput placeholder="Letter content..."></textarea>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</div>