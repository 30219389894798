
// fix models to camel case
export enum Tone {
    formal="formal",
    casual="casual",
}

export enum Language {
    french="french",
    english="english",
    spanish="spanish",
}

export interface CoverLetter {
    text: string;
    tone: Tone;
    language: Language;
}

export interface CreateCoverLetter {
    user_id: string;
    resume_text: string;
    job_offer_text: string;
    tone: Tone | null;
    language: Language | null;
}

export interface Letters {
    id: number;
    company_name: string;
    job_title: string;
    language: string;
    writing_style: string;
    generation_date: Date;
    text: string;
}

export interface Resume {
    id: number;
    name: string;
    text: string;
}

export interface User {
    id: string;
    email: string;
    name: string | undefined;
    credits: number;
    letters: Letters[];
    resumes: Resume[];
}