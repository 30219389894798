import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LetterDataService } from '../services/letter/letter-data.service';
import { FormBuilder } from '@angular/forms';
import { Letters } from '../models/coverLetter';
import { Store } from '@ngrx/store';
import { modifyLetter } from '../store/coverletter.actions';

@Component({
  selector: 'letter-edit-page',
  templateUrl: './letter-edit-page.component.html',
  styleUrls: ['./letter-edit-page.component.css']
})
export class LetterEditPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private letterDataService: LetterDataService,
    private formBuilder: FormBuilder,
    private store: Store,
  ) {}

  letter: Letters | undefined = undefined;

  ngOnInit() {
    const id = this.route.snapshot.params['id'];
    this.loadLetter(id)
  }

  letterForm = this.formBuilder.group({
    companyName: '',
    jobTitle: '',
    language: '',
    writingStyle: '',
    letter: '',
  });

  modifyLetter() {
    if (this.letter) {
      this.store.dispatch(modifyLetter({
        letterId: this.letter.id, 
        companyName: this.letterForm.get('companyName')?.value ?? "", 
        jobTitle: this.letterForm.get('jobTitle')?.value ?? "", 
        language: this.letterForm.get('language')?.value ?? "",
        writingStyle: this.letterForm.get('writingStyle')?.value ?? "",
        text: this.letterForm.get('letter')?.value ?? "",
      }))
    }
  }

  loadLetter(letterId: number) {
    this.letterDataService.getLetter(letterId).subscribe((letter: Letters) => {
      this.letter = letter;
      this.letterForm.patchValue({
        companyName: letter.company_name,
        jobTitle: letter.job_title,
        language: letter.language,
        writingStyle: letter.writing_style,
        letter: letter.text,
      });
    });
  }


}
