import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, take } from 'rxjs';
import { Language, Resume, Tone, User } from '../../models/coverLetter';
import { FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { generateCoverLetter, openNoCreditDialog } from '../../store/coverletter.actions';
import { AppState, selectCoverLetterLoading, selectCurrentUser, selectLetterText } from '../../store/index';
import { ParsingDataService } from 'src/app/services/parsing/parsing-data.service';

@Component({
  selector: 'app-letter-page',
  templateUrl: './letter-page.component.html',
  styleUrls: ['./letter-page.component.css'],
  // encapsulation: ViewEncapsulation.None,
})
export class LetterPageComponent implements OnInit {
  title = 'app';

  documentsForm = this.formBuilder.group({
    resumeText: '',
    jobOfferText: '',
    resumeChoice: '',
  });

  optionForm = this.formBuilder.group({
    language: 'french',
    tone: 'formal',
  });

  letterForm = this.formBuilder.group({
    letter: '',
  });

  ngOnInit(): void {
    this.letterText$.subscribe(text => {
      this.letterForm.controls['letter'].patchValue(text)
    })
  }

  constructor(
    private formBuilder: FormBuilder,
    private parsingDataService: ParsingDataService,
    private store: Store<AppState>
  ) {}

  letterText$: Observable<string> = this.store.select(selectLetterText)
  loading$: Observable<boolean> = this.store.select(selectCoverLetterLoading)
  user$: Observable<User> = this.store.select(selectCurrentUser)

  generateLetter() {
    this.store.select(selectCurrentUser)
    .pipe(take(1))
    .subscribe((user) => {
      if (user && user.credits) {
        const resumeText = this.documentsForm.value.resumeText ?? ""
        const jobText = this.documentsForm.value.jobOfferText ?? ""
        const language = this.optionForm.value.language ?? Language.english
        const tone = this.optionForm.value.tone ?? Tone.formal
        this.store.dispatch(generateCoverLetter({userId: user.id, resumeText: resumeText, jobText: jobText, tone: tone, language: language}))
      } else {
        this.store.dispatch(openNoCreditDialog())
      }
    })
  }

  uploadResume(event: Event) {
    const input = event.target as HTMLInputElement;
    const fileList: FileList | null = input.files;
    if (fileList?.length) {
      this.parsingDataService.parsingResumePdf(fileList[0])
      .subscribe(text => this.documentsForm.controls['resumeText'].patchValue(text))
    }
  }

  loadUserResume(resume: Resume) {
    this.documentsForm.controls['resumeText'].patchValue(resume.text)
  }

  openNoCreditDialog() {
    this.store.dispatch(openNoCreditDialog())
  }

  get letterText() {
    return this.letterForm.get('letter')?.value ?? ""
  }
}
