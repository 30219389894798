import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models/coverLetter';
import { AppState, selectCurrentUser } from 'src/app/store';
import { checkoutSession } from 'src/app/store/coverletter.actions';

@Component({
  selector: 'product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent {
  @Input() productId = '';
  @Input() price = '';

  constructor(private store: Store<AppState>) {
    
  }
  
  buyProduct() {
    this.store.select(selectCurrentUser).subscribe((user: User) => {
      console.log(user)
      this.store.dispatch(checkoutSession({productId: this.productId, userId: user.id}))
    })
  }
}
