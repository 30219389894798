<navbar active="letter"></navbar>
<div class="app-container">
    <div class="mt-5 flex flex-col md:flex-row" *ngrxLet="user$ as user">
        <div class="flex flex-col form-letter-section">
            <form [formGroup]="documentsForm" class="flex flex-col">
                <div class="resumeSection mb-5">
                    <textarea class="text-input-resume text-editor" spellcheck="false" placeholder="Resume content" formControlName="resumeText"></textarea>
                    <div class="tools-resume flex flex-col">
                        <mat-menu class="resume-menu" #appMenu="matMenu">
                            <button mat-menu-item *ngFor="let resume of user.resumes" (click)="loadUserResume(resume)">{{resume.name}}</button>
                        </mat-menu>
                        <button mat-icon-button [matMenuTriggerFor]="appMenu">
                            <mat-icon class="resumeIcons">description</mat-icon>
                        </button>
                        <label for="file-input">
                            <input id="file-input" type="file" (input)="uploadResume($event)"/>
                            <a mat-icon-button>
                                <mat-icon class="resumeIcons">upload_file</mat-icon>
                            </a>
                        </label>
                    </div>
                </div>
                
                <div>
                    <textarea class="text-input-job text-editor" spellcheck="false" placeholder="Job description" formControlName="jobOfferText"></textarea>
                </div>
            </form>

            <div class="mt-5">
                <form [formGroup]="optionForm" class="flex justify-center items-center">
                    <div class="letter-options mr-4">
                        <select class="letter-options-select title text-2xl" formControlName="tone">
                            <option value="" hidden>Choose the format</option>
                            <option value="formal">Formal</option>
                            <option value="casual">Casual</option>
                        </select>
                    </div>

                    <div class="letter-options mr-4">
                        <select class="letter-options-select title text-2xl" formControlName="language">
                            <option value="" hidden>Choose the language</option>
                            <option value="english">English</option>
                            <option value="french">French</option>
                            <option value="spanish">Spanish</option>
                        </select>
                    </div>

                    <button class="generate-btn" mat-button (click)="generateLetter()"><p class="title text-2xl">Generate</p></button>
                </form>
            </div>
        </div>

        <div class="cover-letter-section" *ngrxLet="letterText$ as letter">
            <form [formGroup]="letterForm">
                <div>
                    <textarea class="letter text-editor" formControlName="letter" spellcheck="false" [ngClass]="(loading$ | async) ? 'cover-letter-blured letter-text': 'letter-text'" placeholder="Cover letter content">{{letter}}</textarea>
                </div>
                <mat-spinner class="letter-spinner" [diameter]="50" *ngIf="loading$ | async"></mat-spinner>
            </form>
            <div class="tools flex flex-col ml-4">
                <button mat-icon-button class="copy-text-button mb-2" [cdkCopyToClipboard]="letterText">
                    <mat-icon>content_copy</mat-icon>
                </button>
                <star></star>
            </div>
        </div>
    </div>
</div>



