import { createAction, props } from '@ngrx/store';
import { User } from '../models/coverLetter';

export const generateCoverLetter = createAction('generateCoverLetter', props<{userId: string; resumeText: string; jobText: string; tone: string; language: string; }>());

export const generateCoverLetterSuccess = createAction('generateCoverLetterSuccess', props<{ letterText: string}>());

export const updateCurrentUser = createAction('updateCurrentUser', props<{ user: User}>());

export const checkoutSession = createAction('checkoutSession', props<{ productId: string, userId: string}>());

export const openNoCreditDialog = createAction('openNoCreditDialog');

export const closeNoCreditDialog = createAction('closeNoCreditDialog');

export const removeLetter = createAction('removeLetter', props<{ letterId: number}>());

export const removeLetterSuccess = createAction('removeLetterSuccess');

export const modifyLetter = createAction('modifyLetter', props<{ letterId: number, companyName: string, jobTitle: string, language: string, writingStyle: string, text: string}>());

export const modifyLetterSuccess = createAction('modifyLetterSuccess');

export const downloadLetter = createAction('downloadLetter', props<{ letterId: number}>());

export const addResume = createAction('addResume', props<{ file: File}>());

export const addResumeSuccess = createAction('addResumeSuccess');

export const removeResume = createAction('removeResume', props<{ resumeId: number}>());

export const removeResumeSuccess = createAction('removeResumeSuccess');