import { createSelector } from '@ngrx/store';
import { User } from '../models/coverLetter';

interface coverletterState {
    letterText: string;
    loading: boolean;
    errors: any[];
    resumeText: string;
    currentUser: User;
}

export interface AppState {
    root: coverletterState;
}

const selectAppState = (state: AppState) => state;
   
export const selectCoverLetterLoading = createSelector(
    selectAppState,
    (state: AppState) => state.root.loading
);

export const selectLetterText = createSelector(
    selectAppState,
    (state: AppState) => state.root.letterText
);

export const selectCurrentUser = createSelector(
    selectAppState,
    (state: AppState) => state.root.currentUser
);
