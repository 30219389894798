
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environments';
import { Resume } from 'src/app/models/coverLetter';

@Injectable()
export class ResumeDataService {

    readonly ROOT_URL = environment.API_ENDPOINT_URL + "/resumes"
    constructor(private http: HttpClient) {
    }

    addResume(file: File, userId: string): Observable<Resume> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('user_id', userId);
        return this.http.post<any>(this.ROOT_URL + "/add-resume", formData)
    }

    removeResume(resumeId: number): Observable<Resume> {
        return this.http.get<Resume>(this.ROOT_URL + `/remove/${resumeId}`);
    }

}