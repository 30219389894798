<navbar active="account"></navbar>
<div class="app-container">
    <div class="account-section mt-5" *ngrxLet="user$ as user">
        <div *ngIf="auth.user$ | async as guther">
            <div class="flex flex-col user-section justify-center text-center items-center">
                <img class="user-picture mx-auto mb-4" [src]="guther.picture" width="60">
                <p class="text-xl font-bold">{{user.email}}</p>
                <div class="flex flex-row items-center justify-center mt-20">
                    <div class="flex justify-center mt-20 mr-40 text-4xl">
                        <mat-icon style="transform: scale(3);" class="mr-8">paid</mat-icon>
                        <p>{{user.credits}}</p>
                    </div>
                    <div>
                        <p>You have {{user.credits}} paid credits remaining.</p>
                        <button mat-button mat-stroked-button color="primary" routerLink="/pricing">Buy credits</button>
                        <p class="font-bold">View payment history</p>
                    </div>
                </div>
            </div>

            <div class="resumes flex-col text-center justify-center items-center">
                <p class="text-xl font-bold">Resumes</p>
                <div class="file-container mx-auto">
                    <input class="file-input" id="file-input" accept="application/pdf" type="file" (change)="uploadUserResume($event)"/>
                    <p class="file-input-text"><span class="font-bold">Choose file</span> or drag and drop</p>
                </div>
                <div class="resume-list flex justify-center mt-10">
                    <resume-card [resume]="resume" class="mr-10" *ngFor="let resume of user.resumes"></resume-card>
                </div>
            </div>
            
            <div *ngIf="user.letters.length !== 0; else noLettersBlock" class="flex flex-col letter-history text-center justify-center items-center">
                <p class="mt-10 text-2xl mt-10 font-bold">Cover letter History</p>
                <div class="letter-grid grid grid-cols-1 md:grid-cols-4 gap-12">
                    <letter-card [letter]="letter" *ngFor="let letter of user.letters"></letter-card>
                </div>
            </div>
            <ng-template #noLettersBlock>
                <div class="no-letters flex flex-col text-center items-center">
                    <mat-icon class="mb-2" style="transform: scale(2);">inbox</mat-icon>
                    <p class="text-xl font-bold">No letters were found</p>
                </div>
            </ng-template>
        </div>
    </div>
</div>



