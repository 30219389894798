
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environments';
import { User } from 'src/app/models/coverLetter';

@Injectable()
export class UserDataService {

    readonly ROOT_URL = environment.API_ENDPOINT_URL + "/users"
    constructor(private http: HttpClient) {
    }

    getUserById(userId: string): Observable<User> {
        return this.http.get<User>(this.ROOT_URL + "/user/" + userId);
    }

}