
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environments';
import { Letters } from 'src/app/models/coverLetter';


@Injectable()
export class LetterDataService {

    readonly ROOT_URL = environment.API_ENDPOINT_URL + "/letters"
    constructor(private http: HttpClient) {
    }

    getLetter(letterId: number): Observable<Letters> {
        return this.http.get<Letters>(this.ROOT_URL + `/${letterId}`);
    }

    removeLetter(letterId: number): Observable<Letters> {
        return this.http.get<Letters>(this.ROOT_URL + `/remove/${letterId}`);
    }

    downloadLetter(letterId: number) {
        return this.http.get<Blob>(this.ROOT_URL + `/download/${letterId}`, { responseType: 'blob' as 'json', observe: 'response' });
    }

    modifyLetter(letterId: number, companyName: string, jobTitle: string, language: string, writingStyle: string, text: string): Observable<Letters> {
        let params = {
            company_name: companyName,
            job_title: jobTitle,
            writing_style: writingStyle,
            language: language,
            text: text,
        }
        return this.http.post<Letters>(this.ROOT_URL + `/modify/${letterId}`, params);
    }

}