
<div class="nav-menu flex" *ngIf="user$ | async as user">
    <div class="flex mr-4 items-center">
        <mat-icon>paid</mat-icon>
        <span class="text-lg ml-2 {{user.credits ? '': 'danger'}}">{{user.credits}}</span>
    </div>
    <div class="flex items-center">
        <mat-menu #appMenu="matMenu">
            <button class="menu-items" mat-menu-item routerLink="/account"><mat-icon>person</mat-icon>Account</button>
            <button class="menu-items" mat-menu-item (click)="auth.logout({ logoutParams: { returnTo: document.location.origin } })"><mat-icon>logout</mat-icon>Log out</button>
        </mat-menu>
        <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>account_circle</mat-icon>
        </button>
        <span class="text-lg ml-2">{{user.email}}</span>
    </div>
</div>
