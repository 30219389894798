<navbar active="homepage"></navbar>
<div class="app-container">
    <div *ngIf="!(auth.isAuthenticated$ | async) else launchApp">
        <div class="hook-section flex items-center">
            <div class="flex flex-col items-center mx-auto md:mx-0">
                <span class="text-4xl md:text-6xl mb-8 title">Generate amazing cover letters !</span>
                <span class="text-md md:text-lg mb-8 hook-title">Our online platform allows you to create powerful and convicing letters in order for you to land your dream job.</span>
                <button class="sign-up-btn" mat-button (click)="auth.loginWithRedirect()"><span class="text-2xl title">Try For Free !</span></button>
            </div>
        </div>
        <div class="hero-animation">
            <img src="../../../assets/hero-animation.gif">
        </div>
        <div class="stats-section flex text-center mt-2">
            <div class="flex flex-col mr-20">
                <span class="text-lg">1218</span>
                <span class="text-base text-gray-400">Users</span>
            </div>
    
            <div class="flex flex-col mr-20">
                <span class="text-lg">4.5/5</span>
                <span class="text-base text-gray-400">Trustpilot</span>
            </div>
    
            <div class="flex flex-col">
                <span class="text-lg">7</span>
                <span class="text-base text-gray-400">Languages</span>
            </div>
        </div>
    </div>

    <ng-template #launchApp>
        <div class="hero-animation-logged">
            <img src="../../../assets/hero-animation-logged.gif">
        </div>
        <div class="hook-section-logged flex flex-col justify-center items-center">
            <p class="text-4xl md:text-6xl mb-20 title">Build Cover Letters in a Snap !</p>
            <div class="text-md md:text-lg generate-steps text-left mt-10 mb-10">
                <p><span class="text-2xl dot-items mr-4">1</span>Load your resume</p>
                <p><span class="text-2xl dot-items mr-4">2</span>Copy the desired job offer</p>
                <p><span class="text-2xl dot-items mr-4">3</span>Let AI do the heavy lifting</p>
                <p><span class="text-2xl dot-items mr-4">4</span>Apply for your dream job!</p>
            </div>
            <button class="generate-btn text-2xl" mat-button color="warn" routerLink="/letter"><span class="text-2xl title">Generate</span></button>
        </div>
    </ng-template>
</div>