import { HttpClientModule, HttpParams} from '@angular/common/http'

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environments';
import { CoverLetter } from 'src/app/models/coverLetter';


@Injectable()
export class CoverLetterDataService {

    readonly ROOT_URL = environment.API_ENDPOINT_URL + "/generation"
    constructor(private http: HttpClient) {
    }

    generateTextFromInputs(userId: string, resumeText: string, jobText: string, tone: string, language: string): Observable<CoverLetter> {
        let params = {
            user_id: userId,
            resume_text: resumeText,
            job_text: jobText,
            tone: tone,
            language: language,
        }
        return this.http.post<CoverLetter>(this.ROOT_URL + "/generation", params);
    }

}