import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/coverLetter';
import { AppState, selectCurrentUser } from 'src/app/store';

@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent {
  constructor(public auth: AuthService, private store: Store<AppState>, @Inject(DOCUMENT) public document: Document) {

  }

  user$: Observable<User> = this.store.select(selectCurrentUser)
}
