import { HttpClientModule, HttpParams} from '@angular/common/http'

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environments';

@Injectable()
export class PaymentDataService {

    readonly ROOT_URL = environment.API_ENDPOINT_URL + "/payment"
    constructor(private http: HttpClient) {
    }

    checkoutSession(productId: string, userId: string): any {
        return this.http.post<string>(this.ROOT_URL + "/create-checkout-session", {
            product_id: productId,
            user_id: userId,
        })
    }

}