import { Component } from '@angular/core';

@Component({
  selector: 'payment-success-page',
  templateUrl: './payment-success-page.component.html',
  styleUrls: ['./payment-success-page.component.css']
})
export class PaymentSuccessPageComponent {

}
