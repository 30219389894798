import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/coverLetter';
import { AppState, selectCurrentUser } from 'src/app/store';
import { addResume } from 'src/app/store/coverletter.actions';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.css']
})
export class AccountPageComponent {
  constructor(public auth: AuthService, private store: Store<AppState>){}

  user$: Observable<User> = this.store.select(selectCurrentUser)

  logFile(event: Event) {
    console.log(event)
  }

  uploadUserResume(event: Event) {
    const input = event.target as HTMLInputElement;
    const fileList: FileList | null = input.files;
    if (fileList?.length) {
      this.store.dispatch(addResume({file: fileList[0]}))
    }
  }
}
