import { HttpClientModule, HttpParams} from '@angular/common/http'

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environments';

@Injectable()
export class ParsingDataService {

    readonly ROOT_URL = environment.API_ENDPOINT_URL + "/parsing"
    constructor(private http: HttpClient) {
    }

    parsingResumePdf(file: File): Observable<string> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        return this.http.post<string>(this.ROOT_URL + "/extract-text-pdf", formData);
    }

}