import { createReducer, on } from '@ngrx/store';
import { generateCoverLetter, generateCoverLetterSuccess, updateCurrentUser } from './coverletter.actions';

const initialState = {
    loading: false,
    letterText: "",
    errors: [],
    resumeText: "",
    currentUser: {},
};

export const rootReducer = createReducer(
  initialState,
  on(generateCoverLetter, (state) => ({
    ...state,
    loading: true,
  })),
  on(generateCoverLetterSuccess, (state, action) => ({
    ...state,
    loading: false,
    letterText: action.letterText,
  })),
  on(updateCurrentUser, (state, action) => ({
    ...state,
    currentUser: action.user,
  }))
);