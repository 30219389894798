import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule} from '@angular/common/http'
import {MatButtonModule} from '@angular/material/button'
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import { CoverLetterDataService } from './services/coverLetter/coverLetter-data.service';
import { ReactiveFormsModule } from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import { ClipboardModule } from '@angular/cdk/clipboard';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { LetModule } from '@ngrx/component';
import { StoreModule } from '@ngrx/store';
import { rootReducer } from './store/coverletter.reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/coverletter.effects';
import { HomePageComponent } from './home-page/home-page/home-page.component';
import { RouterModule } from '@angular/router';
import { LetterPageComponent } from './letter-page/letter-page/letter-page.component';
import { AccountPageComponent } from './account-page/account-page/account-page.component';
import { StarComponent } from './components/star/star.component';
import { ParsingDataService } from './services/parsing/parsing-data.service';
import { AuthModule } from '@auth0/auth0-angular';
import { AccountComponent } from './components/account/account.component';
import { UserDataService } from './services/user/user-data.service';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { PricingPageComponent } from './pricing-page/pricing-page.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { PaymentDataService } from './services/payment/payment-data.service';
import { PaymentSuccessPageComponent } from './payment-success-page/payment-success-page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { DialogNoCreditsComponent } from './components/dialog-no-credits/dialog-no-credits.component';
import { LetterCardComponent } from './components/letter-card/letter-card.component';
import { LetterEditPageComponent } from './letter-edit-page/letter-edit-page.component';
import { LetterDataService } from './services/letter/letter-data.service';
import { ResumeCardComponent } from './components/resume-card/resume-card.component';
import { ResumeDataService } from './services/resume/resume-data.service';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    LetterPageComponent,
    AccountPageComponent,
    StarComponent,
    AccountComponent,
    PricingPageComponent,
    ProductCardComponent,
    PaymentSuccessPageComponent,
    NavbarComponent,
    DialogNoCreditsComponent,
    LetterCardComponent,
    LetterEditPageComponent,
    ResumeCardComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot([
      {path: 'payment-success', component: PaymentSuccessPageComponent},
      {path: 'pricing', component: PricingPageComponent},
      {path: 'edit-letter/:id', component: LetterEditPageComponent},
      {path: 'letter', component: LetterPageComponent},
      {path: 'account', component: AccountPageComponent},
      {path: '', component: HomePageComponent},
    ]),
    BrowserAnimationsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    ClipboardModule,
    MatProgressSpinnerModule,
    LetModule,
    StoreModule.forRoot({root: rootReducer}),
    EffectsModule.forRoot([AppEffects]),
    AuthModule.forRoot({
      domain: 'dev-emxykyj8u57ikki5.us.auth0.com',
      clientId: 'o3oulpThhLRCGtuZZXWkshmvyh9mi75v',
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
  ],
  providers: [
    CoverLetterDataService, 
    ParsingDataService, 
    UserDataService, 
    PaymentDataService, 
    LetterDataService,
    ResumeDataService,
    {
      provide: MatDialogRef,
      useValue: {}
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
