import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Letters } from 'src/app/models/coverLetter';
import { downloadLetter, removeLetter } from 'src/app/store/coverletter.actions';

@Component({
  selector: 'letter-card',
  templateUrl: './letter-card.component.html',
  styleUrls: ['./letter-card.component.css']
})
export class LetterCardComponent {
  @Input() letter: Letters | undefined = undefined;

  constructor(private store: Store) {}
  
  showLetter: boolean = false;

  get letterId() {
    return this.letter?.id;
  }

  showLetterToggle() {
    this.showLetter = !this.showLetter;
  }

  get companyName() {
    return this.letter?.company_name ?? ""
  }

  get generationDate() {
    return this.letter?.generation_date ?? ""
  }

  get jobTitle() {
    return this.letter?.job_title ?? ""
  }

  get letterText() {
    return this.letter?.text ?? ""
  }

  get language() {
    return this.letter?.language ?? ""
  }

  get writingStyle() {
    return this.letter?.writing_style ?? ""
  }

  deleteLetter() {
    if (this.letter) {
      this.store.dispatch(removeLetter({letterId: this.letter?.id}))
    }
  }

  downloadLetter() {
    if (this.letter) {
      this.store.dispatch(downloadLetter({letterId: this.letter?.id}))
    }
  }
}
