<div class="navbar-container">
    <div class="flex navbar-laptop items-center justify-between soft-color" *ngIf="!mobile; else navmobile">
        <div class="flex items-center text-4xl">
            <!-- <a routerLink=""><img class="mx-auto" src="../../../assets/logo.png" width="60"></a> -->
            <span class="title">CoverLetter</span><span class="soft-color title">AI</span>
        </div>
        <div *ngIf="active === 'homepage'" class="flex mx-auto">
            <span class="mr-10 text-lg nav-item">About us</span>
            <span class="mr-10 text-lg nav-item">Pricing</span>
            <span class="text-lg nav-item">Blog</span>
        </div>
        <div *ngIf="auth.isAuthenticated$ | async; else loggedOut" class="flex items-center">
            <div class="nav-menu flex items-center">
                <div *ngIf="active !== 'homepage'" class="nav-icons flex mr-10 items-center">
                    <button mat-icon-button routerLink="" routerLinkActive="active"><mat-icon>home</mat-icon></button>
                    <span class="text-lg mr-2">Home</span>
                </div>
                <div *ngIf="active !== 'letter'" class="nav-icons flex mr-10 items-center">
                    <button mat-icon-button routerLink="/letter" routerLinkActive="active"><mat-icon>space_dashboard</mat-icon></button>
                    <span class="text-lg mr-2">Dashboard</span>
                </div>
                <div *ngIf="active !== 'pricing'" class="nav-icons flex mr-10 items-center">
                    <button mat-icon-button routerLink="/pricing" routerLinkActive="active"><mat-icon>storefront</mat-icon></button>
                    <span class="text-lg mr-2">Shop</span>
                </div>
            </div>
            <div>
                <account></account>
            </div>
            
        </div>
        <ng-template #loggedOut>
            <button class="btn-login" mat-button (click)="auth.loginWithRedirect()"><mat-icon>login</mat-icon>Log in</button>
        </ng-template>
    </div>
    <ng-template class="flex justify-center" #navmobile>
        <div *ngIf="auth.isAuthenticated$ | async" class="navmobile flex justify-between text-center">
            <div [ngClass]="{'nav-active': active === 'homepage'}" class="nav-icons ml-5 flex flex-col items-center">
                <button mat-icon-button routerLink="" routerLinkActive="active"><mat-icon>home</mat-icon></button>
                <span class="text-sm mr-2">Home</span>
            </div>
            <div [ngClass]="{'nav-active': active === 'letter'}" class="nav-icons flex flex-col items-center">
                <button mat-icon-button routerLink="/letter" routerLinkActive="active"><mat-icon>space_dashboard</mat-icon></button>
                <span class="text-sm mr-2">Dashboard</span>
            </div>
            <div [ngClass]="{'nav-active': active === 'pricing'}" class="nav-icons flex flex-col items-center">
                <button mat-icon-button routerLink="/pricing" routerLinkActive="active"><mat-icon>storefront</mat-icon></button>
                <span class="text-sm mr-2">Shop</span>
            </div>
            <div [ngClass]="{'nav-active': active === 'account'}" class="nav-icons mr-5 flex flex-col items-center">
                <div class="flex items-center">
                    <mat-menu #appMenu="matMenu">
                        <button class="menu-items" mat-menu-item routerLink="/account"><mat-icon>person</mat-icon>Account</button>
                        <button class="menu-items" mat-menu-item (click)="auth.logout({ logoutParams: { returnTo: document.location.origin } })"><mat-icon>logout</mat-icon>Log out</button>
                    </mat-menu>
                    <button mat-icon-button [matMenuTriggerFor]="appMenu">
                        <mat-icon>account_circle</mat-icon>
                    </button>
                </div>
                <span class="text-sm mr-2">Account</span>
            </div>
        </div>
    </ng-template>
</div>
