
<div [ngClass]="{
    'bronze': this.productId === 'product1',
    'silver': this.productId === 'product2',
    'gold': this.productId === 'product3'
  }" class="flex flex-col p-5 product-card text-center justify-center">
    <p class="text-xl title"><ng-content></ng-content></p>
    <p class="text-4xl title">${{ this.price }}</p>
    <p>Text describing the product and its qualities</p>
    <button mat-button mat-stroked-button color="primary" class="btn-buy-product mx-auto align-center" (click)="buyProduct()"><span class="title text-sm md:text-base">Buy product</span></button>
  </div>
